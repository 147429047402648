jQuery(document).ready(function() {
  jQuery('.toggle-navigation').on('click', function(event) {
    jQuery('body').toggleClass('open-navigation');
    event.preventDefault();
  });

  jQuery('.navbar .expander').on('click', function(event) {
    jQuery(this).parent().next('.sub-menu').slideToggle();
    event.preventDefault();
    event.stopPropagation();
  });

  jQuery('.close-navigation').on('click', function() {
    if (jQuery('body').hasClass('open-navigation')) {
      jQuery('body').removeClass('open-navigation');
    }
    event.preventDefault();
  });

  var $mainSlider = jQuery('.main-slider .slider-wrapper').slick({
    infinite: true,
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000
  });

  var $testimonialsSlider = jQuery('.testimonials .slider-wrapper').slick({
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  });

  var testMobile;
  var isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  function parallaxInit() {
    testMobile = isMobile.any();
    if (testMobile === null) {
      jQuery('.parallax').addClass('attach');
    }
  }

  parallaxInit();

  var wow = new WOW({
    boxClass: 'wow', // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (default is true)
    live: true, // act on asynchronously loaded content (default is true)
    callback: function(box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  });

  wow.init();

  jQuery('.main-slider .video, .welcome .video').magnificPopup({
    type: 'iframe'
  });

  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results)
      return null;
    if (!results[2])
      return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  var loadCategory = 'all';
  var possibleCategories = ['featured', 'current', 'completed'];
  var projectCategory = getParameterByName('project_cat');
  var $loadMore = jQuery('#load-more');
  if(possibleCategories.indexOf(projectCategory) !== -1) {
    loadCategory = '.' + projectCategory;
  }

  var mediaGallery = document.querySelector('#portfolio');
  if (mediaGallery) {
    var mixer = mixitup(mediaGallery, {
      animation: {
        enable: true,
        queueLimit: 12,
        duration: 150
      },
      callbacks: {
        onMixClick: function(state, originalEvent) {
          loadCategory = this.innerText.toLowerCase();
          if (filterItems().length) {
            $loadMore.show();
          }
        }
      },
      load: {
        filter: loadCategory
      }
    });
  }


  // var portfolioItems = [{
  //   category: ['featured'],
  //   url: 'http://google.com',
  //   image: 'images/project-01.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['featured'],
  //   url: 'http://google.com',
  //   image: 'images/project-02.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['featured'],
  //   url: 'http://google.com',
  //   image: 'images/project-03.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['featured'],
  //   url: 'http://google.com',
  //   image: 'images/project-04.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['current'],
  //   url: 'http://google.com',
  //   image: 'images/project-05.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['current'],
  //   url: 'http://google.com',
  //   image: 'images/project-06.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['current'],
  //   url: 'http://google.com',
  //   image: 'images/project-07.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['current'],
  //   url: 'http://google.com',
  //   image: 'images/project-08.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['completed'],
  //   url: 'http://google.com',
  //   image: 'images/project-09.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['completed'],
  //   url: 'http://google.com',
  //   image: 'images/project-10.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['completed'],
  //   url: 'http://google.com',
  //   image: 'images/project-11.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }, {
  //   category: ['completed'],
  //   url: 'http://google.com',
  //   image: 'images/project-12.jpg',
  //   projectTitle: 'Project Name Here',
  //   projectDescription: 'Small overview / project decription that is no more than two lines long',
  //   loaded: false
  // }];


  if (mediaGallery) {
    portfolioItems.forEach(function(item, index) {
      if (index < 9) {
        item.loaded = true;
        var newItem = '<div class="mix item ' + item.category.join(' ') + '"><img src="' + item.image + '" class="img-responsive" alt=""><div class="info"><p class="title">' + item.projectTitle + '</p><p class="description">' + item.projectDescription + '</p><a href="' + item.url + '">Read more</a><span class="category">' + item.category.join(' ') + '</span></div></div>'
        var newElement = document.createElement('div');
        newElement.innerHTML = newItem;
        var elementToInsert = newElement.firstChild;
        mixer.insert(elementToInsert, index);
      }
    });
  }

  var itemsToLoad = [];

  $loadMore.on('click', function(event) {
    itemsToLoad = filterItems();

    var counter = 0;
    itemsToLoad.forEach(function(item, index) {
      if (counter < 6) {
        item.loaded = true;
        var newItem = '<div class="mix item ' + item.category.join(' ') + '"><img src="' + item.image + '" class="img-responsive" alt=""><div class="info"><p class="title">' + item.projectTitle + '</p><p class="description">' + item.projectDescription + '</p><a href="' + item.url + '">Read more</a><span class="category">' + item.category.join(' ') + '</span></div></div>'
        var newElement = document.createElement('div');
        newElement.innerHTML = newItem;
        var elementToInsert = newElement.firstChild;
        mixer.append(elementToInsert);
        counter++;
      }
    });

    if (itemsToLoad.length <= 6) {
      $loadMore.hide();
    }

    itemsToLoad = [];
    event.preventDefault();
  });



  function filterItems() {
    return portfolioItems.filter(function(item, index) {
      if (loadCategory !== 'all' && item.loaded === false && item.category.indexOf(loadCategory) !== -1) {
        return item;
      } else if (loadCategory === 'all' && item.loaded === false) {
        return item;
      }
    });
  }

  var currentPhoto = jQuery('.project-details .main-image-slider').find('.slide-item').eq(0).find('img').attr('src');

  jQuery('.project-details .main-image-slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
    galleryImagesIndex = currentSlide;
    //currentPhoto = jQuery(slick.$slides.get(currentSlide)).find('img').attr('src');
  });

  jQuery('.project-details .thumbs-slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
    var photoCaption = jQuery(slick.$slides.get(currentSlide)).find('img').attr('title');
    jQuery('.project-details .photo-caption').text(photoCaption);
  });

  var $projectDetailMain = jQuery('.project-details .main-image-slider').slick({
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.project-details .thumbs-slider'
  });

  var $projectDetailThumbs = jQuery('.project-details .thumbs-slider').slick({
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.project-details .main-image-slider'
  });

  jQuery('.project-details .action .prev').on('click', function(event) {
    $projectDetailThumbs.slick('slickPrev');
    event.preventDefault();
  });

  jQuery('.project-details .action .next').on('click', function(event) {
    $projectDetailThumbs.slick('slickNext');
    event.preventDefault();
  });

  jQuery('.thumbs-slider .slide-item img').on('click', function (event) {
    var index = jQuery(this).parent().index();
    $projectDetailMain.slick('slickGoTo', index);
    //$projectDetailThumbs.slick('slickGoTo', index);
    event.preventDefault();
  });

  var galleryImages = [];
  var galleryImagesIndex = 0;
  jQuery('.main-image-slider img').each(function () {
    galleryImages.push({
      src: jQuery(this).attr('src')
    });
  });

  jQuery('.project-details .magnify').on('click', function(event) {
    jQuery.magnificPopup.open({
      items: galleryImages,
      gallery: {
        enabled: true
      },
      type: 'image'
    }, galleryImagesIndex);
    event.preventDefault();
  });

  jQuery('.tabs-wrapper .tabs a').on('click', function (event) {
    var $tabItem = jQuery(this).parent();
    var $tabsContainer = $tabItem.parent().parent();
    var section = jQuery(this).attr('href').slice(1);
    if (!$tabItem.hasClass('active')) {
      jQuery('.tabs li').removeClass('active');
      $tabItem.addClass('active');
      $tabsContainer.find('.tab-content').hide().end().find('.tab-content[data-tab-id="' + section + '"]').show();
    }
    event.preventDefault();
  });

  if ('-webkit-appearance' in document.body.style || '-moz-appearance' in document.body.style || 'appearance' in document.body.style) {
    jQuery('.contact .gfield select').addClass('custom-select');
  }

  jQuery('.accordion li .header').on('click', function(event) {
    jQuery(this).parent().toggleClass('open');
    event.preventDefault();
  });

  var contactDivision = getParameterByName('contact_divison');
  if (contactDivision && jQuery('#' + contactDivision).length) {
    jQuery('html, body').animate({
      scrollTop: jQuery('#' + contactDivision).offset().top - 90
    }, 2000);
  }

});



jQuery(window).on('load', function() {
  // var maps = [{
  //   lat: 41.636348,
  //   lng: -72.762550,
  //   map_id: 'map-01'
  // }, {
  //   lat: 39.1774022,
  //   lng: -76.6430642,
  //   map_id: 'map-02'
  // }, {
  //   lat: 42.0876497,
  //   lng: -71.8693674,
  //   map_id: 'map-03'
  // }, {
  //   lat: 39.9703717,
  //   lng: -75.5978865,
  //   map_id: 'map-04'
  // }];

  if (jQuery('.maps .map').length) {
    var maps = jQuery('.map-data');
    maps.each(function(index) {
      var item = jQuery(this);
      var lat = item.data('lat');
      var lng = item.data('lng');
      var mapId = item.attr('id');

      var position = { lat: lat, lng: lng };
      var map = new google.maps.Map(document.getElementById(mapId), {
        zoom: 15,
        center: position,
        scrollwheel: false
      });
      var marker = new google.maps.Marker({
        position: position,
        map: map
      });
    });
  }
});

var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
var $navigation = jQuery('.main-nav');
var navigationPosition = $navigation.offset().top;

jQuery(window).on('scroll', function() {
  var scrollTopPosition = jQuery(window).scrollTop();

  if (scrollTopPosition > navigationPosition) {
    if (!$navigation.hasClass('sticky')) {
      $navigation.addClass('sticky');
    }
  } else {
    if ($navigation.hasClass('sticky')) {
      $navigation.removeClass('sticky');
    }
  }
});

jQuery(window).on('resize', function() {

});